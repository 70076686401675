import './Tile.css';

interface Props {
  image?:string
  number:number,
  tileSize:number
}

const Tile = ({ image, number, tileSize }:Props) => {
  
  const imgSize = tileSize * 0.75;

  const stylesTile = {
    width:`${tileSize}px`,
    height:`${tileSize}px`,
  }

  const stylesImg = {
    width:`${imgSize}px`,
    height:`${imgSize}px`,
    backgroundImage:`url(assets/images/${image})`,
    backgroundSize:`${imgSize}px`,
    cursor:"grabbing"
  }

  return (
    <div
      className={ (number) % 2 === 0 ? "tile tile_black" : "tile tile_white" }
      style={stylesTile}
    >
      { image && <div className="chess_piece" style={stylesImg}></div> }
    </div>
  )
}

export default Tile;