import './ChessBoard.css';
import React, { useState, useEffect, useRef } from 'react';
import Tile from '../Tile/Tile';
import { data} from "../Data/Pieces";
import Referee from '../Referee/Referee';

const axiosX = ["a", "b", "c", "d", "e", "f", "g", "h"];
const axiosY = ["1", "2", "3", "4", "5", "6", "7", "8"];
const initialSize = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;

const ChessBoard = () => {

    const board = []
    const chessBoardRef = useRef<HTMLDivElement>(null);
    const [minSize, setMinSize] = useState(initialSize);

    const [pieces, setPieces] = useState(data);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const [activePiece, setActivePiece] = useState(false);
    const referee = new Referee();

    const screenSize = minSize * 0.9;
    const tileSize = screenSize / 8;

    const styles = {
      width:`${screenSize}px`,
      height:`${screenSize}px`,
      gridTemplateColumns: `repeat(8, ${tileSize}px)`,
      gridTemplateRows: `repeat(8, ${tileSize}px)`
    }

    useEffect(() => {
        const handleResize = () => {
            const minSize = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight
            setMinSize(minSize);
            return minSize;
        }
        window.addEventListener('resize', handleResize)
    }, [minSize])

    const grabPiece = (e:React.MouseEvent) => {
      const element = e.target as HTMLElement;
      const chessBoard = chessBoardRef.current;
      if(element.classList.contains("chess_piece") && chessBoard) {
        setActivePiece(true);
        const x = Math.floor(
          ( e.clientX - chessBoard.offsetLeft) / tileSize
        )
        const y = Math.floor(
          ( chessBoard.offsetTop + screenSize - e.clientY) / tileSize
        )
        setPosX(x);
        setPosY(y);
      } else {
        setActivePiece(false);
      }
    }

    const movePiece = (e:React.MouseEvent) => {
      const element = e.target as HTMLElement;
      const chessBoard = chessBoardRef.current;
      if(activePiece && chessBoard && element.classList.contains("chess_piece")) {

        const minX = chessBoard.offsetLeft;
        const minY = chessBoard.offsetTop;

        const maxX = chessBoard.offsetLeft + (screenSize * 0.9);
        const maxY = chessBoard.offsetTop + (screenSize * 0.9);

        const x = e.clientX - tileSize / 2;
        const y = e.clientY - tileSize / 2;

        element.style.position = "absolute";
        element.style.left = `${x}px`;
        element.style.top = `${y}px`;

        if(x < minX) {
          element.style.left = `${minX}px`;
        } else if(x > maxX) {
          element.style.left = `${maxX}px`;
        } else {
          element.style.left = `${x}px`;
        }
        if(y < minY) {
          element.style.top = `${minY}px`;
        } else if (y > maxY) {
          element.style.top = `${maxY}px`;
        } else {
          element.style.top = `${y}px`;
        }
        //setActivePiece(false);
      }
    }

    const dropPiece = (e:React.MouseEvent) => {
      const element = e.target as HTMLElement;
      const chessBoard = chessBoardRef.current;
      if(activePiece && chessBoard) {
        // calcula a posição que a peça foi solta
        const x = Math.floor(
          (e.clientX - chessBoard.offsetLeft) / tileSize
        )
        const y = Math.floor(
          (chessBoard.offsetTop + screenSize - e.clientY) / tileSize
        )
        setPieces(prevState => {
          const pieces = prevState.map(piece => {
              if(piece.posX === posX && piece.poxY === posY) {
                const moveValid = referee.isValidMove(piece.posX, piece.poxY, x, y, piece.type, piece.team)
                if(moveValid) {
                  piece.posX = x;
                  piece.poxY = y;
                }
              } else {
                  element.style.position="relative";
                  element.style.removeProperty("top");
                  element.style.removeProperty("left");
              }
              return piece;
              })
            return pieces;
          })
      }
      setActivePiece(false);
    }

    for(let y = axiosY.length - 1; y >= 0; y--) {
      for(let x = 0; x < axiosX.length; x++) {
        let image = undefined;
        pieces.forEach(
          p => {
              if(x === p.posX && y === p.poxY) {
                image = p.image;
              }
            }
        )
        board.push(
          <Tile key={`${x},${y}`} image={image} number={x + y} tileSize={tileSize}/>
        )
      }
    }

  return (
    <div
      onMouseMove={e => movePiece(e)}
      onMouseDown={e => grabPiece(e)}
      onMouseUp={e => dropPiece(e)}
      ref={chessBoardRef}
      className='chess_board'
      style={styles}
      >
        {board}
      </div>
  )
}

export default ChessBoard;