import './App.css';
import ChessBoard from './components/ChessBoard/ChessBoard';

const App = () => {
  return (
    <div className='App'>
      <ChessBoard />
    </div>
  );
}

export default App;
