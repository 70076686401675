import { PieceType, TeamType } from "../Data/Pieces";

class Referee {
  isValidMove(prevX:number, prevY:number, posX:number, posY:number, type:PieceType, team:TeamType) {
    console.log("Referee is checking the move...")
    console.log("Previous", prevX, prevY)
    console.log("New location", posX, posY)

    if(type === PieceType.PAWN) {
      if(team === TeamType.WHITE) {
        if( prevY === 1 && prevX === posX && (posY - prevY === 1 || posY - prevY === 2) ) {
          return true;
        } else if( prevX === posX && posY - prevY === 1 ){
          return true;
        } else {
          return false;
        }
      }

      if(team === TeamType.BLACK) {
        if(prevY === 6 && prevX === posX && (posY - prevY === -1 || posY - prevY === -2)) {
          return true;
        } else if(prevX === posX && posY - prevY === -1) {
          return true;
        } else {
          return false;
        }
      }
    }

    if(type === PieceType.ROOK) {
      if(team === TeamType.WHITE) {
        // asd
      }

      if(team === TeamType.BLACK) {

      }
    }
    return false;
  }
}

export default Referee;