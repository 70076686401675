interface Piece {
    image:string,
    posX:number,
    poxY:number
    type:PieceType
    team:TeamType
  }

const pieces:Piece[] = [];

export enum PieceType {
  PAWN,
  ROOK,
  KNIGHT,
  BISHOP,
  KING,
  QUEEN
}

export enum TeamType {
  WHITE,
  BLACK
}


for(let i = 0; i < 8; i ++) {
  // pawn
  pieces.push( {image:"pawn_b.png", posX:i, poxY:6, type:PieceType.PAWN, team:TeamType.BLACK} );
  pieces.push( {image:"pawn_w.png", posX:i, poxY:1, type:PieceType.PAWN, team:TeamType.WHITE} );
}

for(let idx = 0; idx < 2; idx++) {
  const type = idx % 2 ? "b":"w";
  const teamType = type === "b" ? TeamType.BLACK : TeamType.WHITE;
  const y = type === "b" ? 7:0;

  // rook
  pieces.push( {image:`rook_${type}.png`, posX:0, poxY:y, type:PieceType.ROOK, team:teamType} )
  pieces.push( {image:`rook_${type}.png`, posX:7, poxY:y, type:PieceType.ROOK, team:teamType} )

  // knight
  pieces.push( {image:`knight_${type}.png`, posX:1, poxY:y, type:PieceType.KNIGHT, team:teamType} )
  pieces.push( {image:`knight_${type}.png`, posX:6, poxY:y, type:PieceType.KNIGHT, team:teamType} )

  // bishop
  pieces.push( {image:`bishop_${type}.png`, posX:2, poxY:y, type:PieceType.BISHOP, team:teamType} )
  pieces.push( {image:`bishop_${type}.png`, posX:5, poxY:y, type:PieceType.BISHOP, team:teamType} )

  // king
  pieces.push( {image:`king_${type}.png`, posX:3, poxY:y, type:PieceType.KING, team:teamType} )

  // queen
  pieces.push( {image:`queen_${type}.png`, posX:4, poxY:y, type:PieceType.QUEEN, team:teamType} )

}

export const data = pieces;
